import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { CustomWiggle } from 'gsap/CustomWiggle';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { isNil } from 'lodash-es';
import { debounceTime, filter, first, map, tap, withLatestFrom } from 'rxjs';

import { RoutingHistoryService } from '@aw/shared/services/routing-history';
import { isEmptyString } from '@aw/shared/utilities';

import {
  ENV,
  Environment,
  GoogleTagManagerScript,
} from '@aw/prypco/environment';
import { PromptUpdateService } from '@aw/prypco/services/prompt-update';
import {
  AmplitudeService,
  ScriptManagerService,
  initHotJar,
  trackingAllowedRoles,
} from '@aw/prypco/services/script-manager';
import { SeoService } from '@aw/prypco/services/seo';
import { UtmService } from '@aw/prypco/services/utm';
import { AuthFacade } from '@aw/prypco/state/auth';
import { SplashScreenFacade } from '@aw/prypco/state/splash-screen';

@UntilDestroy()
@Component({
  selector: 'po-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
  private readonly scriptManagerService = inject(ScriptManagerService);

  private readonly amplitudeService = inject(AmplitudeService);

  private readonly authFacade = inject(AuthFacade);

  private readonly document = inject(DOCUMENT) as Document;

  private readonly seoService = inject(SeoService);

  private readonly metaService = inject(Meta);

  private readonly promptUpdateService = inject(PromptUpdateService);

  private readonly splashScreenFacade = inject(SplashScreenFacade);

  private readonly routingHistoryService = inject(RoutingHistoryService);

  private readonly utmService = inject(UtmService);

  private readonly env = inject(ENV) as Environment;

  private get splashScreen(): HTMLDivElement {
    return this.document.querySelector('#splashScreen') as HTMLDivElement;
  }

  constructor() {
    this.initExternalScripts();
    this.initGsap();
    this.printVersionMessage();
  }

  ngOnInit(): void {
    this.promptUpdateService.init();
    this.routingHistoryService.init();
    this.utmService.init();

    if (this.metaService) {
      this.setMetaTags();
    }
  }

  private initGsap(): void {
    gsap.registerPlugin(
      ScrollTrigger,
      ScrollSmoother,
      SplitText,
      DrawSVGPlugin,
      CustomWiggle,
      CustomEase,
    );
  }

  private initExternalScripts() {
    const { googleTagManager, hotjar, amplitude } = this.env.externalScripts;

    if (googleTagManager.enabled) {
      this.initGoogleTagManager(googleTagManager);
    }

    if (hotjar.enabled) {
      initHotJar(hotjar);
    }
    if (amplitude.enabled) {
      this.trackAmplitudeIfRolesAllow();
    }
  }

  ngAfterViewInit(): void {
    this.splashScreenFacade.loading$
      .pipe(debounceTime(150), untilDestroyed(this))
      .subscribe({
        next: (isLoading) => {
          if (isLoading) {
            this.showSplashScreen();
          } else {
            this.hideSplashScreen();
          }
        },
      });
  }

  private printVersionMessage(): void {
    const { production: isProduction } = this.env;

    let message = '✨ Application version: 1.2.760';

    if (!isProduction) {
      message = message.replace('1.2.760', 'Local Development');
    }

    console.log(`%c ${message}`, 'color: #bada55');
  }

  private showSplashScreen(): void {
    const { splashScreen } = this;

    splashScreen.style.display = 'flex';
    splashScreen.style.pointerEvents = 'all';
    splashScreen.style.opacity = '1';
  }

  private hideSplashScreen(): void {
    const { splashScreen } = this;

    splashScreen.addEventListener('transitionend', () => {
      this.deleteSplashScreen(splashScreen);
    });

    splashScreen.addEventListener('transitioncancel', () =>
      this.deleteSplashScreen(splashScreen),
    );

    splashScreen.style.opacity = '0';
  }

  private deleteSplashScreen(splashScreen: HTMLDivElement): void {
    this.document.body.removeChild(splashScreen);
  }

  private setMetaTags(): void {
    const { defaultTitle, defaultDescription } = this.seoService;
    const { noIndex } = this.env;

    this.seoService.setTitle(defaultTitle);
    this.seoService.setDescription(defaultDescription);

    if (noIndex) {
      this.metaService.addTag({ name: 'robots', content: 'noindex' });
    }
  }

  private initGoogleTagManager(script: GoogleTagManagerScript): void {
    this.scriptManagerService
      .load(script)
      .pipe(
        filter((externalScript) => !isNil(externalScript)),
        tap(() => {
          const dataLayer = ((window as any).dataLayer || []) as Array<{
            'gtm.start': number;
            event: string;
          }>;

          dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
          });
        }),
        first(),
      )
      .subscribe();
  }

  private trackAmplitudeIfRolesAllow(): void {
    this.authFacade.jobTitle$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.authFacade.id$),
        map(([jobTitle, userId]) => ({
          shouldTrack:
            trackingAllowedRoles.includes(jobTitle) || isEmptyString(jobTitle),
          userId,
        })),
        tap(({ shouldTrack }) => {
          this.amplitudeService.shouldTrack = shouldTrack;
        }),
      )
      .subscribe();
  }
}
